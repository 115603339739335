<template>
  <div>
    <el-upload
        class="avatar-uploader"
        :http-request="upload"
        action="http://api.simpuretech.com/v1/upload/"
        name="uploadFile"
        :with-credentials="true"
        :show-file-list="false"
        :on-success="handleAvatarSuccess">
      <img v-if="url" :src="url" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
const axios = require('axios');
export default {
  name: 'ImgUpload',
  props: ['url'],
  data() {
    return {
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      console.log(res)
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    upload(content) {
      let formData = new FormData();
      formData.append('uploadFile',content.file); // 'file[]' 代表数组 其中`file`是可变的
      axios.defaults.withCredentials = true;
      axios.post(content.action, formData).then(rs=>{
        console.log(rs);
        // this.url = rs.data.data;
        this.$emit('change', rs.data.data)
      }).catch(err=>{
        console.log(err)
      })
    }
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  min-width: 120px;
  min-height: 120px;
  width: 100%;
  max-width: 300px;
  height: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar {
  min-width: 120px;
  min-height: 120px;
  width: 100%;
  max-width: 300px;
  height: auto;
  display: block;
}

</style>
